<template>
  <div class="text-gray-200 bg-primary-grad">
    <MainNav/>

    <template v-if="props.hero">

      <div class="container mx-auto lg:text-center pb-2">
        <h1 class="text-6xl my-1">
          Local Bytes Blog
        </h1>

        <h2 class="text-3xl text-white/75 my-1">
          All the Latest news, tips, and tricks from the Local Bytes Team!
        </h2>

        <PageSocials class="py-2"/>
      </div>
    </template>
  </div>
</template>
<script lang="ts" setup>
const props = defineProps({
  hero: {
    type: Boolean,
    default: true
  }
})
</script>
