<template>
  <div>
    <div class="navbar">
      <NuxtLink to="/">
        <Logo height="50px"/>
      </NuxtLink>
      <template v-for="item in items">
        <div v-if="item.hasOwnProperty('children')" class="dropdown">
          <label class="navbar-item hidden md:block" tabindex="0">{{ item.title }}</label>
          <div class="dropdown-menu dropdown-menu-bottom-center">
            <Component
                :is="child['url'] ? NuxtLink : 'a'"
                v-for="child in (item.children ?? [])"
                :key="child['title']"
                :href="child['href']"
                :to="child['url']"
                class="navbar-item"
                @click="open = false"
            >
              {{ child.title }}
            </Component>
          </div>
        </div>
        <Component
            :is="item['url'] ? NuxtLink : 'a'"
            v-else
            :key="item['title']"
            :href="item['href']"
            :to="item['url']"
            class="navbar-item hidden md:block"
        >
          {{ item.title }}
        </Component>
      </template>
      <LazyThemeSwitcher class="navbar-end max-md:hidden"/>
      <label :for="accordionId" class="ml-auto btn btn-ghost md:hidden">&equiv;</label>

    </div>
    <div class="accordion md:hidden">
      <input :id="accordionId" v-model="open" class="accordion-toggle" type="checkbox"/>
      <div class="accordion-content">
        <div class="min-h-0" tabindex="0">
          <template v-for="item in items" :key="item['title']">
            <MainNavCollapsable
                v-if="item.hasOwnProperty('children')"
                :item="item"
            />
            <Component
                :is="item['url'] ? NuxtLink : 'a'"
                v-else
                :href="item['href']"
                :to="item['url']"
                class="navbar-item block"
                @click="open = false"
            >
              {{ item.title }}
            </Component>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts" setup>
import NuxtLink from "#app/components/nuxt-link";
import Logo from "~/assets/logo/Logo-Landscape.dark.svg?component"

const accordionId = useId();
const open = ref(false);

const items: Navigation = [
  {title: 'Home', url: '/'},
  {title: 'Shop', href: 'https://mylocalbytes.com/'},
  {title: 'Forum', href: "https://forum.mylocalbytes.com/"},
  {
    title: 'Categories',
    children: [
      {title: 'Posts', url: '/posts'},
      {title: 'Knowledgebase', url: '/kb'},
    ]
  },
  {
    title: 'Tools',
    children: [
      {title: "Tasmota Calibration", url: '/tools/tasmota-calibration'},
      {title: "LocalDeck Configurator", url: '/tools/localdeck-configurator'},
    ]
  }
];

</script>

<style lang="scss" scoped>
.navbar {
  @apply shadow-none bg-transparent;
}

.navbar a, :deep(.navbar-item), :deep(.menu-item) {
  @apply text-gray-400 hover:text-secondary;

  &.router-link-active {
    @apply text-gray-100;
  }
}
</style>
