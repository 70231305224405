<template>
  <div>
    <input :id="menuId" class="menu-toggle" type="checkbox"/>
    <label :for="menuId" class="navbar-item justify-between flex flex-row">
      <span>{{ item.title }}</span>
      <span class="menu-icon">
						<svg
                class="w-4 h-4 stroke-content3" fill="none" stroke-width="1.5" viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
            >
							<path d="M8.25 4.5l7.5 7.5-7.5 7.5" stroke-linecap="round" stroke-linejoin="round"/>
						</svg>
					</span>
    </label>

    <div class="menu-item-collapse" @blur="open=false">
      <div class="min-h-0 ">
        <Component
            :is="child['url'] ? NuxtLink : 'a'"
            v-for="child in item.children"
            :key="child['title']"
            :href="child['href']"
            :to="child['url']"
            class="menu-item !bg-transparent"
            @click="open = false"
        >
          {{ child.title }}
        </Component>
      </div>
    </div>
  </div>
</template>
<script lang="ts" setup>
import NuxtLink from "#app/components/nuxt-link";
import type {NavigationItemChildren} from "~/lib/types";

const open = defineModel({
  type: Boolean,
  default: false,
});

defineProps<{
  item: NavigationItemChildren
}>();

const menuId = useId();

const close = () => open.value = false;

</script>
